<template lang="html">
  <section class="manganelli">
    <b-container fluid>
      <b-row align-h="center">
        <b-col sm="12" class="titolo">
          La presente pagina, in forma e funzione di cemeteriale ossequio, è
          dedicata a Giorgio Manganelli, maestro indiscusso dell'oggidiano
          barocco. I testi sono tratti dalle autopresentazioni editoriali che
          accompagnavano le prime edizioni delle sue opere.
        </b-col>
        <b-col sm="12" class="item">
          <img src="https://poesialirica.it/jpg/hilarotragoedia.jpg" />
          Il libretto che qui si presenta è, propriamente, un tattatello, un
          manualetto teorico-pratico; e, come tale, ben si sarebbe schierato a
          fianco di un Dizionarietto del vinattiere di Borgogna e di un Manuale
          del floricultore: testi, insomma, nati dalla lunga e affettuosa
          frequentazione della materia, compilati con diligente pietas da
          studiosi di provincia, socievoli misantropi, mitemente fanatici ed
          astratti; e segretamente dedicati alle anime fraterne, appunto ai
          capziosi delibatori, ai visionari botanici o, come in questo caso, ai
          rari ma costanti cultori della levitazione discenditiva. L’autore,
          umile pedagogo, ambisce alla didattica gloria di aver, se non colmato,
          almeno indicato una lacuna della recente manualistica pratica;
          parendogli cosa stravagante, che, tra tanti completi e dilettosi do it
          yourself, quello appunto si sia trascurato, che ha attinenza con la
          propria morte, variamente intesa. Come usa, e non senza peritosa
          compunzione, si additano qui taluni modesti pregi del volumetto, che
          forse lo differenziano da altri consimili trattati, anche più solenni:
          la definizione di concetti dati troppo spesso per noti, come balistica
          interna ed esterna, angosciastico, adediretto; l’aver proposto una
          nuova, e a nostro avviso, pratica e maneggevole classificazione delle
          angosce; arricchita, inoltre, di un Inserto sugli addii, che a noi
          pare non infima novità della opericciuola; l’inclusione nel discorso
          di cervi e amebe, a sottolineare il carattere più che semplicemente
          umanistico dell’impostazione; e, soprattutto, aver raccolto e
          presentato alcune diligenti e non esigue documentazioni, non senza
          abbozzo di commento, che consentiranno di verificare le enunciazioni
          della parte teoretica; giacché il libro si divide appunto in due
          parti, che potremmo denominare Morfologia ed Esercizi. E se taluno
          troverà codesti documenti inconditi e affatto notarili, non dimentichi
          che il loro pregio è da ricercare nella minuziosa, accanita fedeltà al
          vero; e pertanto, essi vengono qui proposti come esempi di quel
          realismo, moralmente e socialmente significativo, di cui il
          raccoglitore vuol essere ossequioso seguace.
        </b-col>
        <b-col sm="12" class="item">
          <img src="https://poesialirica.it/jpg/nuovocommento.jpg" />
          Vorremmo suggerire al lettore di considerare il libro in cui si
          imbatterà poco oltre in primo luogo come un supporto per copertina;
          destino oggidì non infrequente, e forse non deplorabile, giacché è
          arcaica saggezza che l’eloquenza delle lapidi d’assai migliori i
          lineamenti degli elusivi meschini sottostanti. Potremmo leggere il
          disegno della copertina come una immobile esplosione; ma se ne
          differenzia, e quanto gravemente, in quanto quadrata; dunque è un
          temenos, recinto garantito dagli dèi e, insieme, garante contro le
          inframettenze degli dèi. Pertanto, una pacifica, fruibile esplosione
          che alla inventività del caos allea il rigore di una ben delimitata
          mappa. Ma se scrutiamo l’interno di codesta esplosione quadrata, le
          schegge ci si rilevano segni, numeri, ideogrammi, lettere. Assistiamo
          dunque ad una esplosione alfabetica, alla occupazione nominalistica
          dello spazio? E allora che sarà quella muraglia grafica se non l’esile
          e saldo confine della grammatica? Pare confermare codesta descrizione
          l’ordine, mimesi della sintassi, in cui si dispongono numeri e lettere
          e ideogrammi. Ma si veda come si riveli menzognera codesta disciplina:
          giacché i segni si dispongono sì in bell’ordine, ma non diverso da
          quello morto e letale del dizionario, il frigido elenco alfabetico, la
          scostante serie dei numeri; e dunque la collaborazione sintattica non
          è che una frode, come la compagnevole vicinanza dei sepolcri, o la
          compaginata fraternità degli eserciti. Né, infatti, ci parrebbe
          improprio descrivere codesto quadrato come ordine cemeteriale o
          bellicoso, secondo lo si intenda statico o dinamico. Tuttavia non si
          potrà negare nella mappa, immota o mossa, una vocazione variamente
          centripeta. In tal caso, potremmo forse descrivere il disegno come una
          implosione, un accorrere, contemplativo o passionale, verso il centro
          che grandeggia nel cuore del temenos; unico luogo che, come circolare,
          accoglie imparzialmente o imparzialmente scocca tutti i circostanti
          dardi. Liscio, indifferenziato, quel vacuo centrale può essere
          variamente descritto: come pozzo natale e mortale; luna colta nel
          contraddittorio istante in cui esiste come non luna, una tensione, o
          solo fantasia del cielo, di dar fuori quella bolla di ritmica materia
          luminosa; sole nero, la cui intensità creativa è pari alla tenebrosa
          maestà. Insomma, che i segni escano da quella sede, o a quella
          convergano, o attorno a quella facciano quadrato, pare chiaro che la
          loro fine, o inizio, o consistere, esigano una qualche continuata,
          notturna catastrofe; ed anzi di quella siano designati e compatti. Il
          libro che a tanto disegno si appende non presume di essere didascalia,
          sebbene abbia vocazione indubitabilmente didascalica; ma lietamente ad
          esso si offre come supporto, che ne agevoli il maneggio e il
          trasporto. In tal modo, esso ribadisce la propria qualità servile,
          manualistica, sommariamente informativa; qualcosa che aspira, come a
          proprio ideale, alla concisa emblematicità di un orario ferroviario:
          pagine in cui poche cifre, patetici o arroganti nomi di agglomerati
          umani, indicazioni di neghittosi o precipitosamente scanditi
          itinerari, riassumono empie ambizioni, disperazioni striscianti, tutti
          i dati di un labirinto che nessuna consolazione di tappa o tregua può
          mondare del suo carattere rigorosamente mortale.
        </b-col>
        <b-col sm="12" class="item">
          <img src="https://poesialirica.it/jpg/lunario.jpg" />
          Un giorno, verosimilmente estivo, in un’ora ovviamente crepuscolare un
          romano trionfatore e villoso sgozzò l’ultimo dei sanniti: un omaccione
          odoroso di capra, illetterato, assistito da una teologia “povera”,
          buono ad addomesticare le vipere, avviluppato e impacciato dalle cioce
          di una lingua angusta, difensiva e truce. Probabilmente quell’omicidio
          finale accadde del tutto per caso, e il romano ignorò per sempre di
          aver risolto definitivamente la “questione sannita”, mentre il sannita
          doveva avere più di un sospetto di essere l’ultimo: da troppo tempo
          non trovava interlocutori se non nei sogni, nei lenti incubi che
          rievocavano i banchetti golosi ed aspri delle sue montagne. Forse non
          gli dispiacque morire, e, se la cultura glielo avesse consentito, si
          sarebbe compiaciuto di fare del proprio corpo la parola “fine” ad un
          capitolo della storia peninsulare. La fine dell’ultimo sannita liberò
          per l’Italia un esercito di fantasmi: non erano specialmente temibili,
          anzi tenevano del codardo, erano rozzi, volta a volta depressi o
          fatuamente euforici. Gli antichi romani, costruttori di archi di
          trionfo e macchinatori di stragi storicamente impeccabili, si
          trovarono addosso, ogni notte, quei morti che sembravano aver
          dimenticato tutto della propria uccisione, e che tuttavia restavano
          volgari e sommari. Si sedevano ai piedi del letto dei condottieri e
          dei sergenti, e si mettevano, lì nel cuore della notte, a parlare di
          capretto sulle braci, a fare scommesse infantili e degradanti, a
          parlottare filastrocche dissennate; il sonno dei vincitori divenne,
          più che inquieto, infastidito; provarono a cambiare l’orientamento del
          letto, invocarono i loro dèi fastosi, bruciarono erbette apotropaiche;
          sì i fantasmi tossicchiavano, ma poi riprendevano a ciarlare delle
          loro cose minime e fatue. Col tempo avvenne che, tramite i sogni, che
          aprono le fessure dell’anima e la fanno indifesa, codesti morticini di
          provincia si impiantassero nelle viscere dei romani; si rannicchiarono
          in quei grandi ventri; e i romani scoprirono, poco alla volta, e in
          quest’ordine, i piaceri del capretto sulle braci, delle filastrocche,
          della paura. I vittoriosi divennero difensivi, acquattati, furbi,
          affamati, orfani; ridanciani, golosi, diffidenti e rintanati; sempre
          privi di un documento giustificativo della loro esistenza,
          storicamente strabici, indecorosi e infantili. Non si trascuri la loro
          qualità di orfani: nascendo da una nazione morta essi possedevano come
          nessuno tutte le garanzie dei legami domestici e famigliari, le dolci
          consolazioni dell’infanzia coabitavano con la saggezza venerabile
          della vecchiaia, e l’onesta insolenza della mezza età: ma su tutti
          questi rapporti, questi delicati e fragili colloqui era segnato il
          marchio possessivo della morte: dunque, essi erano domestici in quanto
          derelitti, partecipavano a banchetti d’ombre e nemmeno la morte
          riusciva a renderli meno fatui, meno codardi, meno disorientati. Nel
          presente libro, l’orfano sannita appare un’unica volta, in un contesto
          lievemente indecoroso; per il resto è assolutamente assente,
          professione che gli riesce congeniale e che lo rassicura. Tuttavia,
          egli è il committente — naturalmente analfabeta — e il destinatario di
          questi pezzi che, ispirati dalla codardia, effimeri e pusilli,
          naturalmente gli somigliano.
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script lang="js">

  export default  {
    name: 'manganelli',
    props: [],
    mounted () {},
    data() {
      return {};
    },
    methods: {},
    computed: {},
};
</script>

<style scoped lang="scss">
.manganelli {
  margin-bottom: 25px;
  .titolo {
    font-size: 22px;
    margin: 2px 0px 2px 0px;
    padding: 15px;
  }
  .item {
    margin: 2px 0px 2px 0px;
    padding: 15px;
    background: #b5c6ff;
    img {
      width: 310px;
      float: left;
      margin: 0px 10px 0px 0px;
      border: 2px solid;
    }
  }
}
</style>
